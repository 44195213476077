const btnAddInvite = $('#add-btn')
const invite = $('#new-invite');

const btnEye = $('.show-button');
const userList = $('.users-list');

const btnNavbar = $('#public-toggler');
const menu = $('.navbar-public-tabs');

const isMobile = window.matchMedia("only screen and (max-width: 768px)").matches;


const showElement = (button, element) => {
  button.on('click', () => {
    element.toggleClass('d-none');
  })
}


showElement(btnAddInvite, invite);
showElement(btnEye, userList);
showElement(btnNavbar, menu);


if (isMobile) {
  menu.addClass("d-none");
}

